
import GoBackButton from "@/components/GoBackButton.vue";
import { apiPost } from "@/lib/api_post";
import { MySettlement, MyPostDetail } from "@/models/post";
import { userStore } from "@/store/user";
import {
  defineComponent,
  Ref,
  ref,
  reactive,
  onMounted,
  toRefs,
  computed,
  unref,
} from "vue";
import { useRoute } from "vue-router";
import { copyTextToClipboard } from "@/lib/utils";
import { Toast } from "@/lib/toast";
import { ColumnsType } from "ant-design-vue/lib/table";
import PostCheckInList from "@/components/PostCheckInList.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "ViewPostSettle",
  components: { GoBackButton, PostCheckInList },
  setup() {
    const router = useRouter();
    const columns: ColumnsType = [
      {
        title: "人選名稱",
        key: "lady",
        align: "center",
      },
      {
        title: "是否有報名",
        key: "isSelected",
        align: "center",
      },
      {
        title: "報到時間",
        key: "time",
        dataIndex: ["checkInAt", "humanTime"],
        align: "center",
      },
      {
        key: "price",
        align: "center",
      },
    ];
    const route = useRoute();
    const id: string = route.params.id as string;
    const state = reactive({
      isLoading: false,
      isCandidateSelectable: false,
      isEditing: false,
      ladyList: [] as MySettlement[],
      isListVisible: false,
    });
    const selectedRowKeys = ref<MySettlement["objectId"][]>([]);
    const postDetail: Ref<MyPostDetail> = ref(new MyPostDetail());
    const init = async () => {
      state.isLoading = true;
      await getPostDetail();
      state.ladyList = await getCandidateList();
      state.isLoading = false;
    };
    const getPostDetail = async () => {
      const res = await apiPost.getPostDetail(
        Number(id),
        userStore.ginToken.value
      );
      postDetail.value = new MyPostDetail(res.getInfo());
    };
    const getCandidateList = async () => {
      const res = await apiPost.getPostCandidateList(
        { postId: Number(id) },
        userStore.ginToken.value
      );
      return res.getCandidatesList().map((e) => new MySettlement(e));
    };
    onMounted(async () => {
      await init();
      if (!postDetail.value.isSettleable) {
        Toast.info("此通告不可結算");
        router.replace({ name: "posts" });
      }
    });
    const copy = (text: string) => {
      copyTextToClipboard(text);
      Toast.info("複製成功");
    };
    const rowSelection = ref({
      onChange: (changableRowKeys: MySettlement["objectId"][]) => {
        selectedRowKeys.value = changableRowKeys;
        state.ladyList
          .filter((e) => changableRowKeys.includes(e.objectId))
          .map((e) =>
            e.price === undefined ? (e.price = e.defaultPrice) : false
          );
        state.ladyList
          .filter((e) => !changableRowKeys.includes(e.objectId))
          .map((e) => (e.price = undefined));
      },
    });
    const checkout = async () => {
      try {
        const res = await apiPost.createPostSettleLog(
          {
            postId: postDetail.value.id,
            settleList: state.ladyList
              .filter((e) => selectedRowKeys.value.includes(e.objectId))
              .map((e) => e.toSettleItem()),
          },
          userStore.ginToken.value
        );
        Toast.info("通告結算成功");
        router.push({ name: "posts" });
      } catch (error) {
        Toast.bugOccur();
      }
    };
    const isCheckoutable = computed(() => {
      return state.ladyList.length > 0
        ? selectedRowKeys.value.length > 0 &&
            !state.ladyList
              .filter((e) => selectedRowKeys.value.includes(e.objectId))
              .map((e) => e.price !== undefined && e.price > 0)
              .includes(false)
        : true;
    });
    const showList = () => {
      state.isListVisible = true;
    };
    const closeList = () => {
      state.isListVisible = false;
    };
    return {
      postDetail,
      copy,
      ...toRefs(state),
      columns,
      rowSelection,
      checkout,
      isCheckoutable,
      showList,
      closeList,
    };
  },
});
