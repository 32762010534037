
import { MyCandidate } from "@/models/post";
import { defineComponent, toRefs, reactive, watch } from "vue";
import { MyPostDetail } from "@/models/post";
import { ColumnsType } from "ant-design-vue/lib/table";
export default defineComponent({
  name: "PostCheckInList",
  emits: ["close"],
  props: {
    isRemoteVisible: { required: true, type: Boolean },
    postDetail: { required: true, type: MyPostDetail },
    candidateList: {
      required: true,
      type: Array,
      default: () => {
        const list: MyCandidate[] = [];
        return list;
      },
    },
  },
  setup(props, { emit }) {
    const columns: ColumnsType = [
      {
        title: "人選名稱",
        dataIndex: "name",
        key: "name",
        align: "center",
      },
      {
        title: "uid",
        dataIndex: "uid",
        key: "uid",
        align: "center",
      },
    ];
    const state = reactive({
      isVisible: false,
    });
    const close = () => {
      emit("close");
    };
    watch(
      () => props.isRemoteVisible,
      (val, oldVal) => {
        if (val !== oldVal) {
          state.isVisible = val;
        }
      },
      { immediate: true }
    );
    return { ...toRefs(props), ...toRefs(state), close, columns };
  },
});
